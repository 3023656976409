<template>
    <div class="lockers">
        <store-product 
            :data_products="data_products">
        </store-product>
        <!-- Footer -->
        <main-footer></main-footer>
    </div>
</template>

<script>
export default {
	metaInfo: {
		title: 'Lockers',
		meta: [
			// Ummami Express
			{ name: 'description', content:'Fabrica de muebles para oficinas y estanterías metálicas, escritorios para oficinas, sillas para oficinas, divisiones para oficinas, estanteria metalica, bucaramanga, más de 30 años de experiencia.' },
            { name: 'keywords', content:'archivadores, Lockers, archivadores bucaramanga, Lockers bucaramanga, industrias pico, muebles oficina, muebles oficina Bucaramanga, muebles para oficina Bucaramanga, estantería metálica, estanterías metálicas, fabricas de estanterías metálicas, divisiones para oficina Bucaramanga, escritorios gerenciales Bucaramanga, escritorios para gerencia, Lockers metálicos, fabrica de muebles para oficina, sillas para oficinas Bucaramanga, diseño de oficinas Bucaramanga, muebles para recepción Bucaramanga' },
            { name: 'author', content:'Angecia de Marketing Digital Winketing | Sebastian Báez - Leader Programming Winketing' },
			// SOCIAL
			// OpenGraph data (Most widely used)
			{property: 'og:title', content: 'Lockers'},
			{property: 'og:site_name', content: 'Industrias Pico'},
			{property: 'og:type', content: 'website'},
			{property: 'og:url', content: 'https://industriaspico.com'},
			{property: 'og:image', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'},
			{property: 'og:description', content: 'Lockers en bucaramanga, Fabrica de muebles para oficinas y estanterías metálicas, escritorios para oficinas, sillas para oficinas, divisiones para oficinas, estanteria metalica, bucaramanga, más de 30 años de experiencia.'},
			// Twitter card    
			{name: 'twitter:card', content: 'summary'},
			{name: 'twitter:site', content: 'https://industriaspico.com'},
			{name: 'twitter:title', content: 'Lockers'},
			{name: 'twitter:description', content: 'Fabrica de muebles para oficinas y estanterías metálicas, escritorios para oficinas, sillas para oficinas, divisiones para oficinas, estanteria metalica, bucaramanga, más de 30 años de experiencia.' },
			// Your twitter handle, if you have one.
			// {name: 'twitter:creator', content: '@alligatorio'},
			{name: 'twitter:image:src', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'},
			// Google / Schema.org markup:
			{itemprop: 'name', content: 'Lockers'},
			{itemprop: 'description', content: 'Lockers en bucaramanga, Fabrica de muebles para oficinas y estanterías metálicas, escritorios para oficinas, sillas para oficinas, divisiones para oficinas, estanteria metalica, bucaramanga, más de 30 años de experiencia.' },
			{itemprop: 'image', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'}
		]
    },
    data() {
        return {
            data_products: {
                title_page: 'Lockers',
                types: [
                    { text: 'Todos', value: 'todos' }
                ],
                products: [
                    {
                        name: 'Locker',
                        description: `
                            <p>
                                Este es un mueble modular usado principalmente para organizar espacios 
                                y conservar de forma segura objetos o documentos personales. 
                                Están compuestos en su mayoría por candados o cerraduras independientes 
                                y adicional posee otros tipos de accesorios personalizados. 
                            </p>
                            <p>CARACTERÍSTICAS</p>
                            <ul class="pl-3">
                                <li class="mb-2">Medidas para 1 Casillero: 1.80 cm de alto y 0.40 de ancho.</li>
                                <li class="mb-2">Medidas para 2 Casilleros: 1.80 cm de alto y 0.39 de ancho.</li>
                                <li class="mb-2">Medidas para 9 Casilleros: 1.80 cm de alto y 1.16 de ancho.</li>
                                <li class="mb-2">Contiene sistema de ventilación.</li>
                                <li class="mb-2">Refuerzos en las patas.</li>
                            </ul> 
                            <p>
                                Lockers para colegios, casilleros para empresas, zona húmeda en taquillas de piscina y como armarios independientes. 
                            </p>
                        `,
                        url_image: 'mobiliario-de-supermercado/lockers/productos/locker_1.jpg',
                        slides: [
                            'mobiliario-de-supermercado/lockers/productos/locker_1.jpg',
                            'mobiliario-de-supermercado/lockers/productos/locker_2.jpg',
                            'mobiliario-de-supermercado/lockers/productos/locker_3.png'
                        ],
                        price: '',
                        tags: ['todos']
                    }
                ]
            }
        }
    }
}
</script>